// plugins/ZoneTransportationRatesPlugin.js

export default {
    namespaced: true,
    state: {
      zoneTransportationRate: {
        rateTitle: '',
        startDate: '',
        endDate: '',
        selectedDates: [],
        selectedVehicles: [],
        zonesRates: {}
      },
    },
    mutations: {
      SET_ZONES_RATES(state, payload) {
        state.zoneTransportationRate.zonesRates = payload;
      },
      CLEAR_ZONES_RATES(state) {
        state.zoneTransportationRate = {
          rateTitle: '',
          startDate: '',
          endDate: '',
          selectedDates: [],
          selectedVehicles: [],
          zonesRates: {}
        };
      },
    },

    actions: {

      savezoneTransportationRate({ commit }, zoneTransportationRate) {
        commit('UPDATE_FORM_DATA', zoneTransportationRate);
      },
      updateVehicleRates({ commit }, { zoneId, vehicleId, rates }) {
        commit('SET_VEHICLE_RATES', { zoneId, vehicleId, rates });
      },   
      async get_grouped_rates(_, {Authtoken, userCompany}) {
        try {

          const sessionUser = localStorage.getItem('user');
          const userType = JSON.parse(sessionUser).user.user_type;

          // console.log('userType: ', userType)
          // console.log('userCompany: ', userCompany)
          // console.log('Authtoken', Authtoken);
          // console.log('sessionUser', sessionUser);
          const API_URL = `${this.state.base_URL}grouped-zone-rates`;

          // Create headers object with common headers
          const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Authtoken}`,
            'User-Company': userCompany,
            'User-Type': userType
          };
      
          const res = await fetch(API_URL, {
            method: 'GET',
            headers: headers
          });
      
          const response = await res.json();
      
          console.log('Response from server:', response);
      
          if ('errors' in response) {
            return response.errors;
          }
    
          return response;
        } catch (error) {
          // console.error('Error during query:', error);
          throw new Error(error);
        }
      },   
      async get_all_ZoneTransportationRates(_, {Authtoken, userCompany}) {
        try {

          const sessionUser = localStorage.getItem('user');
          const userType = JSON.parse(sessionUser).user.user_type;

          // console.log('userType: ', userType)
          // console.log('userCompany: ', userCompany)
          // console.log('Authtoken', Authtoken);
          // console.log('sessionUser', sessionUser);
          const API_URL = `${this.state.base_URL}zone-transportation-rates`;

          // Create headers object with common headers
          const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Authtoken}`,
            'User-Company': userCompany,
            'User-Type': userType
          };
      
          const res = await fetch(API_URL, {
            method: 'GET',
            headers: headers
          });
      
          const response = await res.json();
      
          // console.log('Response from server:', response);
      
          if ('errors' in response) {
            return response.errors;
          }
    
          return response;
        } catch (error) {
          // console.error('Error during query:', error);
          throw new Error(error);
        }
      },
      async get_zoneRatesByGroupID(_, {Authtoken, rateGroupID}) {
        try {

          const API_URL = `${this.state.base_URL}search-rates-for-edit/${rateGroupID}`;

          // Create headers object with common headers
          const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Authtoken}`
          };
      
          const res = await fetch(API_URL, {
            method: 'GET',
            headers: headers
          });
      
          const response = await res.json();
      
          // console.log('Response from server:', response);
      
          if ('errors' in response) {
            return response.errors;
          }
    
          return response;
        } catch (error) {
          // console.error('Error during query:', error);
          throw new Error(error);
        }
      },
      async searchAptRates(_,{Authtoken, arrivalDate, departureDate, companyId, zonePerCompanyId, isArrival, isDeparture, vehiclePerCompany_ids, originZone, destinationZone, serviceType, destinationID, hours_booked, allyCode}) {
        try {
          const API_URL = `${this.state.base_URL}zone-transportation-rates/search`;

          // Convertir el arreglo a una cadena JSON
          const vehicleIDSJson = JSON.stringify(vehiclePerCompany_ids);

      
          const res = await fetch(API_URL, {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Rate-Arrival': arrivalDate,
              'Rate-Departure': departureDate,
              'Rate-Company': companyId,
              'Rate-Zone': zonePerCompanyId,
              'isArrival': isArrival,
              'isDeparture': isDeparture,
              'fromZone': originZone,
              'toZone': destinationZone,
              'serviceType': serviceType,
              'vehicleIDS': vehicleIDSJson,
              'destinationID': destinationID,
              'hoursBooked': hours_booked,
              'allyCode': allyCode,
              'Authorization': `Bearer ${Authtoken}`
            },
          });
      
          const response = await res.json();
      
          // console.log('Response from server:', response);
      
          if ('errors' in response) {
            return response.errors;
          }
      
          return response;
        } catch (error) {
          console.error('Error during query:', error);
          throw new Error(error);
        }
      },
      async searchAllyZoneRates(_,allyCode) {
        try {
          const API_URL = `${process.env.VUE_APP_BASEURL}find-public-ally-rates/${allyCode}`;
  
      
          const res = await fetch(API_URL, {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
          });
      
          const response = await res.json();
      
          // console.log('Response from server:', response);
      
          if ('errors' in response) {
            return response.errors;
          }
      
          return response;
        } catch (error) {
          console.error('Error during query:', error);
          throw new Error(error);
        }
      },
      async getZoneTransportationRatesInfo(_,{Authtoken, rateGroupID}) {
        try {
          const API_URL = `${this.state.base_URL}zone-transportation-rates/${rateGroupID}`;
      
          const res = await fetch(API_URL, {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${Authtoken}`
            },
          });
      
          const response = await res.json();
      
          // console.log('Response from server:', response);
      
          if ('errors' in response) {
            return response.errors;
          }
      
          return response;
        } catch (error) {
          console.error('Error during query:', error);
          throw new Error(error);
        }
      },
      async create_ZoneTransportationRates(_, dataToUpdate) {
        try {
          const API_URL = `${this.state.base_URL}zone-transportation-rates`;
          console.log('Data to be sent to server:', dataToUpdate);
          
          // Configura los headers iniciales
          const headers = {
            'Accept': 'application/json',
            'Authorization': `Bearer ${dataToUpdate.token}`,
            'Content-Type': 'application/json'
          };
      
          // Si `hasGroupID` está presente, agrégalo a los headers
          if (dataToUpdate.hasGroupID) {
            headers['hasGroupID'] = dataToUpdate.hasGroupID;
          }
      
          const res = await fetch(API_URL, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(dataToUpdate) // Convierte los datos a una cadena JSON
          });
      
          const response = await res.json();
          // console.log('Response from server:', response);
      
          if ('errors' in response) {
            return response.errors;
          }
      
          return response;
        } catch (error) {
          console.error('Error during query:', error);
          throw new Error(error);
        }
      },
      async create_ZoneTransportationRatesForAirport(_, dataToUpdate) {
        try {
          const API_URL = `${this.state.base_URL}zone-transportation-rates-for-airport`;
          console.log('Data to be sent to server:', dataToUpdate);
          
          // Configura los headers iniciales
          const headers = {
            'Accept': 'application/json',
            'Authorization': `Bearer ${dataToUpdate.token}`,
            'Content-Type': 'application/json'
          };
      
          // Si `hasGroupID` está presente, agrégalo a los headers
          if (dataToUpdate.hasGroupID) {
            headers['hasGroupID'] = dataToUpdate.hasGroupID;
          }
      
          const res = await fetch(API_URL, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(dataToUpdate) // Convierte los datos a una cadena JSON
          });
      
          const response = await res.json();
          // console.log('Response from server:', response);
      
          if ('errors' in response) {
            return response.errors;
          }
      
          return response;
        } catch (error) {
          console.error('Error during query:', error);
          throw new Error(error);
        }
      },      
      async update_ZoneTransportationRates(_, dataToUpdate) {
        try {
          
          // console.log('dataToUpdate.rateID', dataToUpdate.rateID);
          const API_URL = `${this.state.base_URL}zone-transportation-rates/${dataToUpdate.rateID}`;
          // console.log('API_URL', API_URL);

          // Crear un objeto zoneTransportationRate
          let zoneTransportationRate = new zoneTransportationRate();
          
          // Agregar los datos y la imagen al objeto zoneTransportationRate
          for (const key in dataToUpdate) {
            if (key !== 'token' && dataToUpdate[key] !== null) {
              zoneTransportationRate.append(key, dataToUpdate[key]);
            }
          }
          zoneTransportationRate.append('_method', 'PUT'); // Suplantación del método
    
      
          const res = await fetch(API_URL, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Authorization': `Bearer ${dataToUpdate.token}`
              // No establecer 'Content-Type' aquí, el navegador lo hará automáticamente
            },
            body: zoneTransportationRate
          });
      
          const response = await res.json();
      
          // console.log('Response from server:', response);
      
          if ('errors' in response) {
            return response.errors;
          }
      
          return response;
        } catch (error) {
          console.error('Error during query:', error);
          throw new Error(error);
        }
      },
      async deactivate_ZoneTransportationRates(_, { Authtoken, rateGroupID }) {
        try {
          
          // console.log('rateGroupID', rateGroupID)
          const API_URL = `${this.state.base_URL}zone-rates/update-batch/${rateGroupID}`;
      
          const res = await fetch(API_URL, {
            method: 'PUT',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${Authtoken}`
            },
            body: JSON.stringify({
              zoneTransportationRate_status: 2
            })
          });
      
          const response = await res.json();
      
          // console.log('Response from server:', response);
      
          if ('errors' in response) {
            return response.errors;
          }
      
          return response;
        } catch (error) {
          console.error('Error during query:', error);
          throw new Error(error);
        }
      },
      async activate_ZoneTransportationRates(_, { Authtoken, rateGroupID }) {
        try {
          
          const API_URL = `${this.state.base_URL}zone-rates/update-batch/${rateGroupID}`;
      
          const res = await fetch(API_URL, {
            method: 'PUT',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${Authtoken}`
            },
            body: JSON.stringify({
              zoneTransportationRate_status: 1
            })
          });
      
          const response = await res.json();
      
          // console.log('Response from server:', response);
      
          if ('errors' in response) {
            return response.errors;
          }
      
          return response;
        } catch (error) {
          console.error('Error during query:', error);
          throw new Error(error);
        }
      },
      async delete_ZoneTransportationRates(_, { Authtoken, rateGroupID }) {
        try {
          console.log('rateGroupID', rateGroupID);
          const API_URL = `${this.state.base_URL}zone-rates/remove-batch/${rateGroupID}`;
      
          const res = await fetch(API_URL, {
            method: 'DELETE',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${Authtoken}`
            },
          });
      
          const response = await res.json();
      
          // console.log('Response from server:', response);
      
          if ('errors' in response) {
            return response.errors;
          }
      
          return response;
        } catch (error) {
          console.error('Error during query:', error);
          throw new Error(error);
        }
      },
      async delete_ZoneTransportationRates_permanently(_, { Authtoken, rateID }) {
        try {
          
          const API_URL = `${this.state.base_URL}zone-transportation-rates/${rateID}`;
      
          const res = await fetch(API_URL, {
            method: 'DELETE',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${Authtoken}`
            },
          });
      
          const response = await res.json();
      
          // console.log('Response from server:', response);
      
          if ('errors' in response) {
            return response.errors;
          }
      
          return response;
        } catch (error) {
          console.error('Error during query:', error);
          throw new Error(error);
        }
      },
      // ... otras acciones relacionadas con los empresas
    },
    
    getters: {
      getzoneTransportationRate(state) {
        return state.zoneTransportationRate;
      },
    },
  }