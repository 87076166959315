import { createStore } from 'vuex'
import usersPlugin from '../plugins/usersPlugin.js'
import customersPlugin from '../plugins/customersPlugin.js'
import authPlugin from '../plugins/authPlugin.js'
import companiesPlugin from '../plugins/companiesPlugin.js'
import paymentsPlugin from '../plugins/paymentsPlugin.js'
import bookingOptionsPlugin from '../plugins/bookingOptionsPlugin.js'
import globalFleetPlugin from '../plugins/globalFleetPlugin.js'
import FleetPlugin from '../plugins/FleetPlugin.js'
import globalZonesPlugin from '../plugins/globalZonesPlugin.js'
import ZonesPerCompanyPlugin from '../plugins/ZonesPerCompanyPlugin.js'
import TransportationAddonsPlugin from '../plugins/TransportationAddonsPlugin.js'
import TransportationRatesPlugin from '../plugins/TransportationRatesPlugin.js'
import ZoneTransportationRatesPlugin from '../plugins/ZoneTransportationRatesPlugin.js'
import AptReservationsPlugin from '../plugins/AptReservationsPlugin.js'
import AddonsBookedPlugin from '../plugins/AddonsBookedPlugin.js'
import paymentLinksPlugin from '../plugins/paymentLinksPlugin.js'
import tripsPlugin from '../plugins/tripsPlugin.js'
import GlobalPlugin from '../plugins/GlobalPlugin.js'
import billingPlugin from '../plugins/billingPlugin.js'
import CouponsPlugin from '../plugins/CouponsPlugin.js'
import MarketingChannelsPlugin from '../plugins/MarketingChannels.js'
import CommercialAlliesPlugin from '../plugins/CommercialAllies.js'
import SalesRepsPlugin from '../plugins/SalesRepsPlugin.js'
import TranspDestPlugin from '../plugins/TranspDestPlugin.js'
import LandingPages from '../plugins/LandingPages.js'
import ReviewsPlugin from '../plugins/ReviewsPlugin.js'
import registerCompany from '../plugins/registerCompany.js'

export default createStore({
  state: {
    APP_NAME: process.env.VUE_APP_TITLE,
    APP_MAIN_DOMAIN: process.env.VUE_APP_MAIN_DOMAIN,
    base_URL: process.env.VUE_APP_BASEURL,
    company_logo: '',
    isSidebarVisible: false, // Añadido
  },
  mutations: {
    updateCompanyLogo(state, newLogo) {
      state.company_logo = newLogo;
    },
    toggleSidebar(state) {
      state.isSidebarVisible = !state.isSidebarVisible;
    },
  },
  modules: {
    userStore: usersPlugin,
    customersStore: customersPlugin,
    companyStore: companiesPlugin,
    paymentsStore: paymentsPlugin,
    bookingOptionsStore: bookingOptionsPlugin,
    globalFleetStore: globalFleetPlugin,
    FleetStore: FleetPlugin,
    globalZonesStore: globalZonesPlugin,
    ZonesPerCompanyStore: ZonesPerCompanyPlugin,
    TransportationAddonsStore: TransportationAddonsPlugin,
    TransportationRatesStore: TransportationRatesPlugin,
    ZoneTransportationRatesStore: ZoneTransportationRatesPlugin,
    AptReservationsStore: AptReservationsPlugin,
    AddonsBookedStore: AddonsBookedPlugin,
    paymentLinksStore: paymentLinksPlugin,
    tripsStore: tripsPlugin,
    authStore: authPlugin,
    globalStore: GlobalPlugin,
    billingStore: billingPlugin,
    CouponsStore: CouponsPlugin,
    MarketingChannelsStore: MarketingChannelsPlugin,
    CommercialAlliesStore: CommercialAlliesPlugin,
    SalesRepsStore: SalesRepsPlugin,
    TranspDestStore: TranspDestPlugin,
    LandingPagesStore: LandingPages,
    ReviewsStore: ReviewsPlugin,
    registerCompanyStore: registerCompany,
  }
})
