// plugins/registerCompany.js

  const state = {
    RegistryObject: {
      user: {
        user_fname: '',
        user_lname: '',
        user_email: '',
        user_phone: '',
        user_password: '',
      },
      company: {
        company_name: '',
        company_email: '',
        company_phone: '',
        company_subdomain: '',
        selectedCities: [],
      },
    },
  };
  
  const mutations = {
    SET_REGISTRY_OBJECT(state, payload) {
      state.aptReservations = { ...state.aptReservations, ...payload };
    },
    CLEAR_REGISTRY_OBJECT(state) {
      state.RegistryObject = {
        user: {
          user_fname: '',
          user_lname: '',
          user_email: '',
          user_phone: '',
          user_password: '',
        },
        company: {
          company_name: '',
          company_email: '',
          company_phone: '',
          company_subdomain: '',
          selectedCities: [],
        },
      };
    },
    SETDEV_REGISTRY_OBJECT(state) {
      state.RegistryObject = {
        user: {
          user_fname: 'Steven',
          user_lname: 'Galmander',
          user_email: 'steve@newcompany.com',
          user_phone: '0000000000',
          user_password: '123456',
        },
        company: {
          company_name: 'New Company',
          company_email: 'admin@newcompany.com',
          company_phone: '6241231234',
          company_subdomain: 'newcompany',
          selectedCities: ['Baja California Sur'],
        },
      };
    },
  };
  
  const actions = {
    updateRegistryObject({ commit }, payload) {
      commit('SET_REGISTRY_OBJECT', payload);
    },
    clearRegistryObject({ commit }) {
      commit('CLEAR_REGISTRY_OBJECT');
    },   
    async get_all_TranspDest() {
      try {

        const API_URL = `${process.env.VUE_APP_BASEURL}public/transportation_destinations`;
        // console.log('userType: ', userType)

        // Create headers object with common headers
        const headers = {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        };
    
        const res = await fetch(API_URL, {
          method: 'GET',
          headers: headers
        });
    
        const response = await res.json();
    
        // console.log('Response from server:', response);
    
        if ('errors' in response) {
          return response.errors;
        }
  
        return response;
      } catch (error) {
        // console.error('Error during query:', error);
        throw new Error(error);
      }
    },

    async registerNewCompany(_, dataToUpdate) {
      try {
        const API_URL = `${this.state.base_URL}register-company`;
        // console.log('JSON.stringify(dataToUpdate)', JSON.stringify(dataToUpdate));
        // console.log('dataToUpdate: ', dataToUpdate);

        const payload = {
          registryObject: JSON.stringify(dataToUpdate.registryObject),
        };

        const res = await fetch(API_URL, {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload)
        });

        const response = await res.json();
    
        if ('errors' in response) {
          return response.errors;
        }
    
        return response;
      } catch (error) {
        console.error('Error during query:', error);
        throw new Error(error);
      }
    },
  };
  
  const getters = {
    getRegistryObject(state) {
      return state.RegistryObject;
    },
    // ... otros getters que puedas necesitar
  };
  
  export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
  };